import type { ActionFunctionArgs } from "@remix-run/node"
import { data } from "@remix-run/node"
import { useFetcher, useFetchers } from "@remix-run/react"
import { z } from "zod"

import { useRequestInfo } from "@/lib/hooks/use-request-info"
import { useHints } from "@/lib/utils/client-hints"
import { setTheme } from "@/lib/utils/theme-cookie.server"

const ThemeFormSchema = z.object({
	theme: z.enum(["system", "light", "dark"]),
})

export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(
		(f) => f.formAction === "/utils/theme-switch",
	)

	if (themeFetcher && themeFetcher.formData) {
		const theme = themeFetcher.formData.get("theme")
		if (theme && ThemeFormSchema.shape.theme.safeParse(theme).success) {
			return theme as "system" | "light" | "dark"
		}
	}
}

export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === "system" ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

export function useThemeSwitch() {
	const requestInfo = useRequestInfo()
	const userPreference = requestInfo.userPrefs.theme
	const fetcher = useFetcher()
	const optimisticMode = useOptimisticThemeMode()

	const mode = optimisticMode ?? userPreference ?? "system"

	const setTheme = (theme: "light" | "dark" | "system") => {
		fetcher.submit(
			{ theme },
			{ method: "POST", action: "/utils/theme-switch" },
		)
	}

	return [mode, setTheme] as const
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const result = ThemeFormSchema.safeParse(Object.fromEntries(formData))

	if (!result.success) {
		return data({ error: "Invalid theme received" }, { status: 400 })
	}

	const { theme } = result.data

	const responseInit = {
		headers: { "set-cookie": await setTheme(theme) },
	}

	return data({ success: true }, { ...responseInit, status: 200 })
}
